<template>

<div>
    <div class="row red accent-2">
        <div class="container">
            <br>
            <h2 class="grey-text text-darken-4" style="margin:0; padding: 0; font-weight: bold">
                <span v-if="$route.params.lang == 'it'">I Nostri Producer</span>
                <span v-if="$route.params.lang == 'en'">Our Producers</span>
            </h2>
            <br>
        
        </div>
    </div>
    <div class="row">
        <div class="container">
             <div class="col s12 m4">
                <div class="card">
                    <div class="card-image">
                        <img src="https://i.ibb.co/F0L1Czv/ab6761610000e5eb01c11098f4bcd84658acae56.jpg">
                    </div>
                    <div class="card-stacked">
                        <div class="card-content">
                            <h4 style="margin: 0; font-weight: bold">Paolo</h4>
                            <br>
                            <p v-if="$route.params.lang == 'it'">
                                Con un portfolio di produzione condiviso con artisti come Tom Swoon, Nicky Romero, Kenn Colt, Nari &amp; Milani, Il Pagante e altri, Hiisak è il fondatore di H-Music Studio. Oggi le sue produzioni ricevono supporto da BBC Radio1, m2o Radio, Radio Deejay, Fun Radio and SiriusXM tra gli altri.
                            </p>
                            <p v-if="$route.params.lang == 'en'">
                               With a production portfolio shared with artists like Tom Swoon, Nicky Romero, Kenn Colt, Nari &amp; Milani and others, Hiisak is H-Music Studios' founder. Today his productions are supported by BBC Radio1, m2o Radio, Radio Deejay, Fun Radio and SiriusXM among others.
                            </p><br>
                            <a href="https://www.instagram.com/hiisak_music/" style="color: inherit"><i style="font-size: 20px" class="fa fa-instagram">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>
                            <a href="https://www.facebook.com/hiisakmusic" style="color: inherit"><i style="font-size: 20px" class="fa fa-facebook">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>
                            <a href="https://twitter.com/hiisakmusic" style="color: inherit"><i style="font-size: 20px" class="fa fa-twitter">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>
                        </div>
                        <div class="card-action red-text text-accent-2">
                        <a class="red-text text-accent-2" href="./apply?source=hiisak">
                            <span v-if="$route.params.lang == 'it'">Richiedi Preventivo</span>
                            <span v-if="$route.params.lang == 'en'">Get Quote</span>
                        </a>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s12 m4">
                <div class="card">
                    <div class="card-image">
                        <img src="https://i.ibb.co/BPNs3Q9/274573208-3247416728916861-4140622778236439901-n.jpg">
                    </div>
                    <div class="card-stacked">
                        <div class="card-content">
                            <h4 style="margin: 0; font-weight: bold">Ivan</h4>
                            <br>
                            <p v-if="$route.params.lang == 'it'">
                                Con più di 10 milioni di streams complessivi sui DSP, Yvvan Back è uno dei nomi più interessanti nella scena house, avendo spesso raggiunto le vette delle calssifiche musicali di Beatport e Traxsource.
                            </p>
                            <p v-if="$route.params.lang == 'en'">
                               Counting more than 10 million streams across different DSPs, Yvvan Back is one of the most interesting names in the house music scene, having often reached the summit of charts such as Beatport and Traxsource.
                            </p><br>
                            <a href="https://www.instagram.com/yvvanback/" style="color: inherit"><i style="font-size: 20px" class="fa fa-instagram">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>
                            <a href="https://www.facebook.com/yvvanback" style="color: inherit"><i style="font-size: 20px" class="fa fa-facebook">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>                        </div>
                        <div class="card-action red-text text-accent-2">
                        <a class="red-text text-accent-2" href="./apply?source=Ivan">
                            <span v-if="$route.params.lang == 'it'">Richiedi Preventivo</span>
                            <span v-if="$route.params.lang == 'en'">Get Quote</span>
                        </a>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s12 m4">
                <div class="card">
                    <div class="card-image">
                        <img src="https://i.ibb.co/KWNgQN8/Whats-App-Image-2022-03-30-at-9-32-09-AM.jpg">
                    </div>
                    <div class="card-stacked">
                        <div class="card-content">
                            <h4 style="margin: 0; font-weight: bold">Francesco</h4>
                            <br>
                            <p v-if="$route.params.lang == 'it'">
                                Con un forte background nella produzione musicale per l'advertising, Francesco Rea ha lavorato a numerosi progetti internazionali e le sue produzioni sono state usate da aziende del calibro di Calzedonia. 
                            </p>
                            <p v-if="$route.params.lang == 'en'">
                               With a strong background in music production for advertising, Francesco Rea has worked on numerous international projects and his productions have been used by companies such as Calzedonia.
                            </p>
                            <br>
                            <a href="https://www.instagram.com/rea_francesco/" style="color: inherit"><i style="font-size: 20px" class="fa fa-instagram">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>
                            <a href="https://www.facebook.com/francesco.rea.ERROR808" style="color: inherit"><i style="font-size: 20px" class="fa fa-facebook">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>                        </div>
                        <div class="card-action red-text text-accent-2">
                        <a class="red-text text-accent-2" href="./apply?source=Francesco">
                            <span v-if="$route.params.lang == 'it'">Richiedi Preventivo</span>
                            <span v-if="$route.params.lang == 'en'">Get Quote</span>
                        </a>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</div>
    


</template>

<script>
export default {
    data(){
        return{
            meta:{
                title:{
                    it: "H-Music Studio | I Nostri Producer",
                    en: "H-Music Studio | Our Production Team"
                },
                description: {
                    it: "Situato a Varese, H-Music studio è lo studio di registrazione, produzione, mixing e mastering di fiducia di artisti come Il Pagante, Promise Land, Jamis e tanti altri.",
                    en: "Based near Milan, Italy, H-Music Studio is the go-to solution for recording, mixing, mastering, producing for artists like Promise Land, LILO, Monocule and many more."
                }
            }
        }
    },
    metaInfo() {
        return { 
            htmlAttrs: {
                lang: this.$route.params.lang,
            },
            title: this.meta.title[this.$route.params.lang],
            meta: [
                { name: 'description', content:  this.meta.description[this.$route.params.lang]},
                { property: 'og:title', content: this.meta.title[this.$route.params.lang]},
                { property: 'og:site_name', content: 'H-Music Studio'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>