<template>
    <div>

        

        <div  v-if="!$store.state.adminLoggedIn" style="text-align:center">
            <a @click="$store.commit('logIn')" class="waves-effect red accent-2 waves-light btn" style="margin: auto; margin: 30px"><i class="material-icons left">login</i>Accedi all'Area Riservata</a>
        </div>

        <div v-show="$store.state.adminLoggedIn" class="container">
            <ul class="collapsible" v-show="requests.length>0">
                <li v-for="request in requests" :key="request.id">
                    <div class="collapsible-header"><i class="material-icons">person</i>{{request.name}} <span class="grey-text" style="margin-left: auto; margin-right: 0;">{{request.received}}</span></div>
                    <div class="collapsible-body white">
                        
                        <div class="row">
                            <div class="col s12 l2">
                                <p>{{request.email}}</p>
                            </div>
                            <div class="col s12 l8">
                                <p>{{request.message}}</p>
                            </div>
                            <div class="col s12">
                                <a v-if="request.converted == true" class="waves-effect green accent-2 waves-light btn"><i class="material-icons left">check_box</i>Cliente Convertito</a>
                                <a v-if="request.converted == false" @click="$store.dispatch('flagreq',request.id)" class="waves-effect grey accent-2 waves-light btn"><i class="material-icons left">check_box_outline_blank</i>Contrassegna come cliente convertito</a>
                            </div>
                        </div>
                        
                    </div>
                </li>
            </ul>
            <p class="grey-text" v-if="requests.length==0">Non ci sono richieste nel database</p>
            <div  v-if="$store.state.adminLoggedIn" style="text-align:center">
                <a @click="$store.commit('logout')" class="waves-effect red accent-2 waves-light btn" style="margin: auto; margin: 30px"><i class="material-icons left">login</i>Esegui il Logout</a>
            </div>
        </div>

    </div>
</template>

<script>
export default {

    mounted(){
        /* global $ */
        $(document).ready(function(){
            $('.collapsible').collapsible();
        });

    },
    computed: {
        requests() {
            return this.$store.state.requests;
        },
    }
    
}
</script>