<template>

  <div  v-schema:scope="{ type: 'LocalBusiness'}">

    <div class="row" style="margin: 0; padding: 0">
      <div class="col s12" style="display: flex; align-items: center; text-align: center; height: 500px; background-size: cover; background-position: center; background-image: url(https://i.ibb.co/3y53kyp/bg-hmusic.gif)">
        <div class="col s12">
          <h1 v-schema="{ prop: 'name'}" class="transparent-text" style="margin:0; padding: 0;font-weight: bold; text-stroke: 2px white">H-MUSIC STUDIO</h1>
          <p class="white-text" style="margin:0; padding: 0"><span v-if="$route.params.lang == 'en'">a Puzzle Music Group company</span><span v-if="$route.params.lang == 'it'">Parte di Puzzle Music Group</span></p>
        </div>
      </div>
    </div>
    <div class="row red accent-2" style="margin: 0; padding: 0">
        <div class="col l1 s0"></div>
        <div class="col l3 s12">
          <br><br>
          <p class="grey-text text-darken-4" style="margin:0; padding: 0; white-space: nowrap"><span v-if="$route.params.lang == 'it'">Perchè H-Music Studio</span><span v-if="$route.params.lang == 'en'">Why H-Music Studio</span></p>
          <h2 class="grey-text text-darken-4" style="margin:0; padding: 0; font-weight: bold"><span v-if="$route.params.lang == 'it'">Chi Siamo</span><span v-if="$route.params.lang == 'en'">About Us</span></h2>
        </div>
        <div class="col l8 s12">
          <br><br>
          <p v-schema="{ prop: 'description'}" class="grey-text text-darken-4"><span v-if="$route.params.lang == 'en'">For Artists Like Promise Land, LILO or Monocule, H-Music Studio is like home. <br><br> For labels and distributors like Spinnin' Records, Believe, Hexagon or Musical Freedom, H-Music Studio is a trustworthy partner.</span><span v-if="$route.params.lang == 'it'">Per artisti come Il Pagante, Georgia Mos o Marco Carpentieri, H-Music Studio è casa. <br><br> Per etichette e distributori come Spinnin' Records, Believe, Hexagon o Musical Freedom, H-Music Studio è un partner su cui contare.</span></p>
          <p class="grey-text text-darken-4" style="font-weight: bold"><span v-if="$route.params.lang == 'en'">In 2021 alone, our mixes, masters and productions have reached 10 million and 122 thousand ears, through Spotify alone.</span><span v-if="$route.params.lang == 'it'">Solo nel 2021, i nostri mix, master e le nostre produzioni hanno raggiunto 10 milioni e 122 mila orecchie, solo su Spotify.</span></p>
          <br><br>
      </div>
    </div>
    <div class="row" style="margin: 0; padding: 0">
        <div class="col l1 s0"></div>
        <div class="col s12 l3">
          <br><br>
          <p class="white-text" style="margin:0; padding: 0; white-space: nowrap"><span v-if="$route.params.lang == 'it'">Perchè H-Music Studio</span><span v-if="$route.params.lang == 'en'">Why H-Music Studio</span></p>
          <h2 class="white-text" style="margin:0; padding: 0; font-weight: bold"><span v-if="$route.params.lang == 'it'">Artisti</span><span v-if="$route.params.lang == 'en'">Artists</span></h2>
          <br><br>
        </div>
        <div class="col s12 l8" >
          <div class="row" id="artistcarousel" style="overflow-x: auto; white-space: nowrap!important;margin: 0; padding: 0">
            <span v-for="(artist,index) in artists" :key="index">
              <a v-bind:href="$route.params.lang+'/artist/'+artist.short">
                <div class="artist-carousel-el" v-bind:style="'background-image: url('+artist.img+');position: relative; background-size: cover; background-position: center; width: 300px; height: 300px; display: inline-flex; text-align: center; align-items: center'">
                  <h5 class="white-text" style="width: 100%; font-weight: bold; z-index: 3">{{artist.name}} <br> <span class="artist-carousel-sub" style="opacity: 0%; font-size: 10px; font-weight: normal"></span></h5>
                  <div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: rgba(0,0,0,0.3); z-index: 2" class="artist-carousel-bg"></div>
                </div>
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="row red accent-2" style="margin: 0; padding: 0">
        <div class="col l1 s0"></div>
        <div class="col s12 l3">
          <br><br>
          <p class="grey-text text-darken-4" style="margin:0; padding: 0; white-space: nowrap"><span v-if="$route.params.lang == 'it'">Perchè H-Music Studio</span><span v-if="$route.params.lang == 'en'">Why H-Music Studio</span></p>
          <h2 class="grey-text text-darken-4" style="margin:0; padding: 0; font-weight: bold"><span v-if="$route.params.lang == 'it'">Servizi</span><span v-if="$route.params.lang == 'en'">Services</span></h2>
        </div>
        <div class="col s12 l8">
          <br>
          <div class="row"> 
            <div  v-schema:scope="{ type: 'Service', prop: 'itemListElement' }"  class="col l6 s12 grey darken-4" style="padding: 15px; margin-bottom: 10px;  border: 1px solid black; height: 300px; transform: scale(95%)">
              
              <h5 v-schema="{ prop: 'name'}" class="red-text text-accent-2" style="margin: auto; font-weight: bold;">Mixing &amp; Mastering</h5>
              <p class="white-text">
                <span v-if="$route.params.lang == 'en'">Give your track the shine it deserves. </span>
                <span v-if="$route.params.lang == 'it'">Dona alla tua traccia lo splendore che merita.</span>
                <br><br> <a style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/service/mixing-mastering'"><span v-if="$route.params.lang == 'en'">Check out our work</span><span v-if="$route.params.lang == 'it'">Dai un'occhiata ai nostri lavori</span></a><br><a style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/services'"><span v-if="$route.params.lang == 'it'">Sfoglia le nostre soluzioni ad-hoc</span><span v-if="$route.params.lang == 'en'">Browse our taylor-made solutions</span></a>
                <br>
                <a v-schema="{ prop: 'url'}" style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/apply'">
                  <span v-if="$route.params.lang == 'it'">Contattaci o richiedi un preventivo</span>
                  <span v-if="$route.params.lang == 'en'">Contact us or get a quote</span>
                </a>
              </p>
            </div>
            <div v-schema:scope="{ type: 'Service', prop: 'itemListElement' }" class="col l6 s12 grey darken-4" style="padding: 15px; margin-bottom: 10px; border: 1px solid black; height: 300px; transform: scale(95%)">
              
              <h5 v-schema="{ prop: 'name'}" class="red-text text-accent-2" style="margin: auto; font-weight: bold">Production, Full Production &amp; Additional Production</h5>
              <p class="white-text">
                <span v-if="$route.params.lang == 'en'">Our team of producers and songwriters available for your project or your entire roster.</span>
                <span v-if="$route.params.lang == 'it'">Il nostro team di produttori e songwriters a disposizione del tuo progetto o del tuo roster intero.</span>
                <br><br>
                <a v-schema="{ prop: 'url'}" style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/producers'">
                  <span v-if="$route.params.lang == 'it'">Scopri i nostri produttori</span>
                  <span v-if="$route.params.lang == 'en'">Meet our producers</span>
                </a>
                <br>
                <a style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/service/prod'"><span v-if="$route.params.lang == 'en'">Check out our work</span><span v-if="$route.params.lang == 'it'">Dai un'occhiata ai nostri lavori</span></a><br><a style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/services'"><span v-if="$route.params.lang == 'it'">Sfoglia le nostre soluzioni ad-hoc</span><span v-if="$route.params.lang == 'en'">Browse our taylor-made solutions</span></a>
                <br>
                <a v-schema="{ prop: 'url'}" style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/apply'">
                  <span v-if="$route.params.lang == 'it'">Contattaci o richiedi un preventivo</span>
                  <span v-if="$route.params.lang == 'en'">Contact us or get a quote</span>
                </a>
                
              </p>
            </div>
            <div v-schema:scope="{ type: 'Service', prop: 'itemListElement' }" class="col l6 s12 grey darken-4" style="padding: 15px; margin-bottom: 10px; border: 1px solid black; height: 300px; transform: scale(95%)">
              
              <h5 v-schema="{ prop: 'name'}" class="red-text text-accent-2" style="margin: auto; font-weight: bold">Recording</h5>
              <p class="white-text">
                <span v-if="$route.params.lang == 'en'">State of the art recording at our studios in Varese.</span>
                <span v-if="$route.params.lang == 'it'">Registrazioni allo stato dell'arte presso i nostri studi a Varese.</span>
                <br><br><a style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/services'"><span v-if="$route.params.lang == 'it'">Sfoglia le nostre soluzioni ad-hoc</span><span v-if="$route.params.lang == 'en'">Browse our taylor-made solutions</span></a>
                <br>
                <a v-schema="{ prop: 'url'}" style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/apply'">
                  <span v-if="$route.params.lang == 'it'">Contattaci o richiedi un preventivo</span>
                  <span v-if="$route.params.lang == 'en'">Contact us or get a quote</span>
                </a>
              </p>
            </div>
            <div v-schema:scope="{ type: 'Service', prop: 'itemListElement' }"  class="col l6 s12 grey darken-4" style="padding: 15px; margin-bottom: 10px; border: 1px solid black; height: 300px; transform: scale(95%)">
              
              <h5 v-schema="{ prop: 'name'}" class="red-text text-accent-2" style="margin: auto; font-weight: bold">Strategy &amp; Production Consltancy</h5>
              <p class="white-text">
                <span v-if="$route.params.lang == 'en'">Need sponsors to produce a music video? Need a film crew? Need a public relations campaign, or to verify an artists' social profile? Need smart ways to develop your roster? We're here for you.</span>
                <span v-if="$route.params.lang == 'it'">Servono sponsor per produrre un video musicale? Serve una troupe cinematografica? Serve una campagna di pubbliche relazioni, o verificare il profilo di un artista sui social? Servono soluzioni smart per sviluppare il roster? Siamo qui per questo.</span>
                <br><br>
                <a style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/services'">
                  <span v-if="$route.params.lang == 'it'">Sfoglia le nostre soluzioni ad-hoc</span>
                  <span v-if="$route.params.lang == 'en'">Browse our taylor-made solutions</span>
                </a>
                <br>
                <a v-schema="{ prop: 'url'}" style="color: inherit; text-decoration: underline" v-bind:href="$route.params.lang+'/apply'">
                  <span v-if="$route.params.lang == 'it'">Contattaci o richiedi un preventivo</span>
                  <span v-if="$route.params.lang == 'en'">Contact us or get a quote</span>
                </a>
              </p>
            </div>
            
          </div>
          <br>
        </div>
      </div>
      <div class="row" style="margin: 0; padding: 0">
        <div class="col l1 s0"></div>
        <div class="col s12 l3">
          <br><br>
          <p class="white-text" style="margin:0; padding: 0; white-space: nowrap"><span v-if="$route.params.lang == 'it'">Perchè H-Music Studio</span><span v-if="$route.params.lang == 'en'">Why H-Music Studio</span></p>
          <h2 class="white-text" style="margin:0; padding: 0; font-weight: bold"><span v-if="$route.params.lang == 'it'">Casi Studio</span><span v-if="$route.params.lang == 'en'">Case Studies</span></h2>
          <br><br>
        </div>
        <div class="col s12 l8" >
           <div class="row" style="overflow-x: auto; overflow-y: hidden; white-space: nowrap!important;">
              <span v-for="(caseStudy,index) in caseStudies" :key="index" >
                <div v-if="!caseStudy.hidden" class="card" style="background-color: black;white-space: normal; display: inline-block; width: 70vw; margin: 10px; max-width: 400px">
                  <div class="card-image">
                    <img v-bind:src="caseStudy.mainImage">
                    <span class="card-title">{{caseStudy.title[$route.params.lang]}}</span>
                  </div>
                  <div class="card-content black white-text" style="position: relative">
                    <div style="position: absolute; width: 100%; height: 90%; top: 10%; left: 0; background-image: linear-gradient( rgba(0,0,0,0), black);"></div>
                    <p style="max-width: 100%; max-height: 120px;overflow: hidden;text-overflow: ellipsis;">{{caseStudy.date[$route.params.lang]}}<br><br>{{caseStudy.paragraphs[0].text[$route.params.lang]}}</p>
                  </div>
                  <div class="card-action black">
                    <a class="red-text text-accent-2 title underline" v-bind:href="'/'+$route.params.lang+'/case-study/'+caseStudy.short"><span v-if="$route.params.lang=='it'">Leggi il caso studio</span><span v-if="$route.params.lang=='en'">Read the case study</span></a>
                  </div>
                </div>
              </span>
              

            </div>
        </div>
      </div>
  </div>
</template>

<style>
.artist-carousel-bg, .artist-carousel-sub{
  transition: 0.2s;
}
.artist-carousel-el:hover .artist-carousel-bg{
  background-color: rgba(255, 0, 0, 0.8)!important;
}
.clickable:hover{
  color: red!important;
}
.artist-carousel-el:hover .artist-carousel-sub{
  opacity: 100%!important;
}
</style>

<script>
/* global $ */
export default {
  data(){
    return{
      caseStudies: [],
      artists: [],
      meta:{
          title:{
              it: "H-Music Studio | Studio di Registrazione e Varese",
              en: "H-Music Studio | Recording Studio in Milan, Italy"
          },
          description: {
              it: "Situato a Varese, H-Music studio è lo studio di registrazione, produzione, mixing e mastering di fiducia di artisti come Il Pagante, Promise Land, Jamis e tanti altri.",
              en: "Based near Milan, Italy, H-Music Studio is the go-to solution for recording, mixing, mastering, producing for artists like Promise Land, LILO, Monocule and many more."
          }
      }
    }
  },
  
  mounted(){
    $.get('.netlify/functions/get_artists').then(res=>{
      /* this.projects = JSON.parse(res) */
      let projects = JSON.parse(res)

      let artists = []

      projects.forEach(element => {
        element.artists.forEach(artist => {
          if(artists.filter(e => e.name === artist.name).length == 0){
            artists.push(artist)
          }
        });
      });

    let shuffled = artists.map((value) => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
        

      this.artists = shuffled


    })
    $.get('.netlify/functions/get_case_studies').then(res=>{
      this.caseStudies = JSON.parse(res).reverse()
    })

    setTimeout(() => {

      setInterval(function(){
        if(!document.getElementById('artistcarousel').matches(':hover')){
        document.getElementById('artistcarousel').scrollLeft ++
          if(document.getElementById('artistcarousel').scrollLeft > (document.getElementById('artistcarousel').scrollWidth - document.getElementById('artistcarousel').clientWidth-1)){
            console.log('true')
            document.getElementById('artistcarousel').scrollLeft = 0
          }
        }
        
      }, 10);
      
    }, 500);
    
    
    
    
    
  },
  metaInfo() {
      return { 
          htmlAttrs: {
              lang: this.$route.params.lang,
          },
          title: this.meta.title[this.$route.params.lang],
          meta: [
              { name: 'description', content:  this.meta.description[this.$route.params.lang]},
              { property: 'og:title', content: this.meta.title[this.$route.params.lang]},
              { property: 'og:site_name', content: 'H-Music Studio'},
              {property: 'og:type', content: 'website'},    
              {name: 'robots', content: 'index,follow'} 
          ]
      }
  }
}
</script>