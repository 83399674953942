import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CaseStudy from '../views/CaseStudy.vue'
import Services from '../views/Services.vue'
import Service from '../views/Service.vue'
import Artist from '../views/Artist.vue'
import Apply from '../views/Apply.vue'
import Producers from '../views/Producers.vue'
import Admin from '../views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang',
    name: 'Home',
    component: Home
  },
  {
    path: '/:lang/case-study/:title',
    name: 'Case Study',
    component: CaseStudy,
  },
  {
    path: '/:lang/services/',
    name: 'Services',
    component: Services
  },
  {
    path: '/:lang/artist/:name',
    name: 'Artist',
    component: Artist
  },
  {
    path: '/:lang/service/:name',
    name: 'Service',
    component: Service
  },
  {
    path: '/:lang/apply/',
    name: 'Apply',
    component: Apply
  },
  {
    path: '/:lang/producers/',
    name: 'Producers',
    component: Producers
  },
  {
    path: '/:lang/admin/',
    name: 'Admin Console',
    component: Admin,
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
