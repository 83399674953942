<template>
    <div class="container">
        <div class="row">
            <br>
            <div class="col s12 l6" v-bind:style="'height: 50vh; background-image: url('+post.mainImage+'); background-size: cover; background-position: center'"></div>
            <div class="col s12 l6">
                <h4 class="title red-text text-accent-2">{{post.title[$route.params.lang]}}</h4>
                <p class="white-text">{{post.subtitle[$route.params.lang]}}</p>
                <p class="grey-text prod-small">{{post.date[$route.params.lang]}}</p>
            </div>
        </div>
        <div class="row">
            <div v-for="paragraph in post.paragraphs" :key="paragraph.text.it">
                <div v-if="!paragraph.image.exists" class="col s12">
                    <p class="white-text">{{paragraph.text[$route.params.lang]}}</p>
                    <div style="text-align: right">
                        <a v-bind:href="paragraph.link.url"><p v-if="paragraph.link.exists" class="red-text text-accent-2"><i class="material-icons right tiny">open_in_new</i>{{paragraph.link.text[$route.params.lang]}}</p></a> 
                    </div>
                </div>
                <div v-if="paragraph.image.exists" class="col s12 l6">
                    <p class="white-text">{{paragraph.text[$route.params.lang]}}</p>
                    
                </div>
                <div v-if="paragraph.image.exists" class="col s12 l6" style="text-align:right">
                    <img v-bind:src="paragraph.image.url" alt="" class="responsive-img">
                    <a v-bind:href="paragraph.link.url"><p v-if="paragraph.link.exists" class="red-text text-accent-2"><i class="material-icons right tiny">open_in_new</i>{{paragraph.link.text[$route.params.lang]}}</p></a> 
                </div>
            </div>
            <div class="col s12">
                <br><br>
            </div>
        </div>
    </div>
</template>

<script>
/* global $ */
export default {
    data(){
        return{
            post: {},
            meta:{
                title:{
                    it: "",
                    en: ""
                },
                description: {
                    it: "",
                    en: ""
                }
            }
        }
    },
    mounted(){
        $.get('/.netlify/functions/get_post_by_short?short='+this.$route.params.title).done(res=>{
            this.post = JSON.parse(res)
            this.meta.title.it = "H-Music Studio | " + JSON.parse(res).title.it + " // Caso Studio del progetto"
            this.meta.title.en = "H-Music Studio | " + JSON.parse(res).title.en + " // Project Case Study"
            this.meta.description.it = JSON.parse(res).paragraphs[0].text.it
            this.meta.description.en = JSON.parse(res).paragraphs[0].text.en
        }).fail(err=>{
            console.log(err)
            
        })
    },
    metaInfo() {
        return { 
            htmlAttrs: {
                lang: this.$route.params.lang,
            },
            title: this.meta.title[this.$route.params.lang],
            meta: [
                { name: 'description', content:  this.meta.description[this.$route.params.lang]},
                { property: 'og:title', content: this.meta.title[this.$route.params.lang]},
                { property: 'og:site_name', content: 'H-Music Studio'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>