<template>
    <div class="container">
        <div class="row">
            <div class="col s12 l4" v-for="(project,index) in projects" :key="index">
                <div class="card">
                    <div class="card-image">
                    <img v-bind:src="project.img">
                    <a v-bind:href="project.fanlink" class="btn-floating halfway-fab waves-effect waves-light red accent-2"><i class="material-icons">play_arrow</i></a>
                    </div>
                    <div class="card-content black">
                        <div>
                            <span class="card-title white-text" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-weight: bold">{{project.title}}</span>
                            <p class="grey-text"><span v-if="project.commissionType.includes('mixing-mastering')">Mixing &amp; Mastering</span><span v-if="project.commissionType.length>1">, </span><span v-if="project.commissionType.includes('prod')">Production</span><span v-if="project.commissionType.length>2">, </span><span v-if="project.commissionType.includes('video')">Video Production</span></p>
                        </div><br>
                        <div class="red-text text-accent-2" style="height: 80px; overflow: auto;"><p>{{project.highlights}}</p></div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* global $ */
export default {
    data(){
        return{
            projects: [],
            meta:{
                title:{
                    it: "",
                    en: ""
                },
                description: {
                    it: "",
                    en: ""
                }
            }
        }
    },
    mounted(){
        $.get('/.netlify/functions/get_artists').then(res=>{
        let newCollection = []

        JSON.parse(res).forEach(element => {
            
            element.artists.forEach(artist => {
                if(artist.short == this.$route.params.name){
                    this.meta.title.it = "H-Music Studio | I nostri lavori per "+artist.name
                    this.meta.title.en = "H-Music Studio | Work we've done for "+artist.name
                    this.meta.description.en = "See all the songs by "+artist.name+" which we have mixed, mastered and produced."
                    this.meta.description.it = "Scopri tutti i pezzi di "+artist.name+" che abbiamo mixato, masterizzato o prodotto."
                    newCollection.push(element)
                }
            });
        });
        
       

        this.projects = newCollection
        
        

        


        })
    },
    metaInfo() {
        return { 
            htmlAttrs: {
                lang: this.$route.params.lang,
            },
            title: this.meta.title[this.$route.params.lang],
            meta: [
                { name: 'description', content:  this.meta.description[this.$route.params.lang]},
                { property: 'og:title', content: this.meta.title[this.$route.params.lang]},
                { property: 'og:site_name', content: 'H-Music Studio'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>