<template>
    <div class="container">
        <div class="row">
            <div class="col s12 l4" v-for="(project,index) in projects" :key="index">
                <div class="card">
                    <div class="card-image">
                    <img v-bind:src="project.img">
                    <a v-bind:href="project.fanlink" class="btn-floating halfway-fab waves-effect waves-light red accent-2"><i class="material-icons">play_arrow</i></a>
                    </div>
                    <div class="card-content black">
                        <div>
                            <span class="card-title white-text" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-weight: bold">{{project.title}}</span>
                            <p class="grey-text"><span v-if="project.commissionType.includes('mixing-mastering')">Mixing &amp; Mastering</span><span v-if="project.commissionType.length>1">, </span><span v-if="project.commissionType.includes('prod')">Production</span><span v-if="project.commissionType.length>2">, </span><span v-if="project.commissionType.includes('video')">Video Production</span></p>
                        </div><br>
                        <div class="red-text text-accent-2" style="height: 80px; overflow: auto;"><p>{{project.highlights}}</p></div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* global $ */
export default {
    data(){
        return{
            projects: [],
            meta:{
                title:{
                    it: "",
                    en: ""
                },
                description: {
                    it: "H-Music è uno studio di registrazione a Varese vicino Milano. Visualizza tutti i nostri lavori",
                    en: "H-Music is a recording studio in Varese, close to Milan. Browse our work."
                }
            }
        }
    },
    mounted(){
        if(this.$route.params.name == 'prod'){
            this.meta.title.it = "H-Music Studio | I brani che abbiamo prodotto";
            this.meta.title.en = "H-Music Studio | Tracks we produced"
        }
        if(this.$route.params.name == 'rec'){
            this.meta.title.it = "H-Music Studio | I brani che abbiamo registrato";
            this.meta.title.en = "H-Music Studio | Tracks we recorded"
        }
        if(this.$route.params.name == 'mixmaster'){
            this.meta.title.it = "H-Music Studio | I brani che abbiamo Mixato e Masterizzato";
            this.meta.title.en = "H-Music Studio | Tracks we mixed and mastered"
        }
        $.get('/.netlify/functions/get_artists').then(res=>{
        let newCollection = []

        JSON.parse(res).forEach(element => {
            if(element.commissionType.includes(this.$route.params.name)){
                newCollection.push(element)
            }
        });
        
       let shuffled = newCollection.map((value) => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
    

        this.projects = shuffled
        
        

        


        })
    },
    metaInfo() {
        return { 
            htmlAttrs: {
                lang: this.$route.params.lang,
            },
            title: this.meta.title[this.$route.params.lang],
            meta: [
                { name: 'description', content:  this.meta.description[this.$route.params.lang]},
                { property: 'og:title', content: this.meta.title[this.$route.params.lang]},
                { property: 'og:site_name', content: 'H-Music Studio'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>