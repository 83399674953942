<template>
  <!-- <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div> -->

  
  <div>

    
    <div id="intro" class="black" style="width: 100vw; height: 100vh; position: fixed; z-index: 1000; top: 0; left:0; display: flex; align-items: center; text-align: center">
      <div class="row">
        <div class="col s12">
          <h6 class="white-text animate__animated animate__fadeInDown" style="margin: 0px" >Talent is Good</h6>
        </div>
        <div class="col s12">
          <h5 class="white-text animate__animated animate__fadeInDown" style="margin: 0px; animation-delay: 0.5s">Practice is Better</h5>
        </div>
        <div class="col s12">
          <h4 class="white-text animate__animated animate__fadeInDown" style="margin: 0px; animation-delay: 1s">Passion is Best<span class="red-text">.</span></h4>
        </div>
      </div>
    </div>

<div class="grey darken-4">
    <div class="navbar-fixed z-depth-0">
      <nav class="z-depth-0 black">
      <div class="nav-wrapper black z-depth-0">
        <img class="brand-logo center" style="padding: 10px; filter: invert(1); height: 100%" src="./assets/hmusiclogo.svg" alt="">
        <a href="#" data-target="mobile-demo" class="sidenav-trigger right"><i class="material-icons">menu</i></a>
        <ul id="nav-mobile" class="right hide-on-med-and-down">
          <li v-if="$store.state.adminLoggedIn" class="red accent-2"><router-link v-bind:to="'/it/admin'"><li><a><i class="material-icons left">admin_panel_settings</i>Accedi all'area amministratori</a></li></router-link></li>
          
          <li><router-link v-bind:to="'/'+$route.params.lang"><li><a>Home</a></li></router-link></li>
          <li><a v-bind:href="'/'+$route.params.lang+'/services'" ><span v-if="$route.params.lang == 'it'">Servizi</span><span v-if="$route.params.lang == 'en'">Services</span></a></li>
          <li><a v-bind:href="'/'+$route.params.lang+'/apply'" ><span v-if="$route.params.lang == 'it'">Contatti</span><span v-if="$route.params.lang == 'en'">Contact</span></a></li>
        </ul>
      </div>
    </nav>
    </div>
    <ul class="sidenav black" id="mobile-demo">
      <li v-if="$store.state.adminLoggedIn" class="red accent-2"><router-link v-bind:to="'/it/admin'"><li><a><i class="material-icons left">admin_panel_settings</i>Area admin</a></li></router-link></li>
      <li><router-link v-bind:to="'/'+$route.params.lang"><a class="white-text">Home</a></router-link></li>
      <li><a class="white-text" v-bind:href="'/'+$route.params.lang+'/services'" ><span v-if="$route.params.lang == 'it'">Servizi</span><span v-if="$route.params.lang == 'en'">Services</span></a></li>
      <li><a class="white-text" v-bind:href="'/'+$route.params.lang+'/apply'" ><span v-if="$route.params.lang == 'it'">Contatti</span><span v-if="$route.params.lang == 'en'">Contact</span></a></li>
    </ul>
    <router-view/>
      <div class="row black">
          <div class="container">
          <div class="col s6 l4 grey-text">
            <p>
              <span v-if="$route.params.lang == 'en'" class="title">Contact us:<br><br></span>
              <span v-if="$route.params.lang == 'it'" class="title">Contatti:<br><br></span>
              <span>info@hmusic.studio</span><br><br>
              <a href="https://www.instagram.com/hmusicstudio/" style="color: inherit"><i style="font-size: 20px" class="fa fa-instagram">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>
              <a href="https://www.facebook.com/hmusicstudioit" style="color: inherit"><i style="font-size: 20px" class="fa fa-facebook">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>
              <a href="https://twitter.com/HMUSICSTUDIO1" style="color: inherit"><i style="font-size: 20px" class="fa fa-twitter">&nbsp;&nbsp;&nbsp;&nbsp;</i></a>
              
            </p>
          </div>
          <div class="col s12 grey-text center-align">
            <p class="prod-small">
              &copy; 2021 - Puzzle Music Group srl <br>
              <span v-schema:scope="{ type: 'PostalAddress', prop: 'address'}">
                <span v-schema="{ prop: 'streetAddress'}">
                  Via Alessandro Manzoni, 21047
                </span>
                <span v-schema="{ prop: 'addressLocality'}">
                  Saronno
                </span>,
                <span v-schema="{ prop: 'addressRegion'}">
                  VA
                </span>
              </span>
            </p>
          </div>
          </div>
        </div>
          
      </div>

  </div>
  
    
</template>

<script>
/* global $ */
export default {
  created(){
    /* global netlifyIdentity */
    netlifyIdentity.on('init', user => {
      if(user){
        this.$store.commit('loggedIn')
      }
    });
    netlifyIdentity.on('login', () => {
      this.$store.commit('loggedIn')
    });
    netlifyIdentity.on('logout', () => {
      this.$store.commit('guest')
    });
  },
  mounted(){
    console.log(this.$route.name)
    if(this.$route.name == 'Home'){
      document.getElementById('intro').style.display = 'flex'
      setTimeout(function(){
        document.getElementById('intro').classList.add('animate__animated')
        document.getElementById('intro').classList.add('animate__fadeOut')
        setTimeout(function(){
          document.getElementById('intro').style.display = 'none'
        },600)
      }, 3000);
    }else{
      document.getElementById('intro').style.display = 'none'
    }
    
    $(document).ready(function(){
      $('.sidenav').sidenav();
    });
    if(!this.$route.params.lang){
      console.log(navigator.language)
      if(navigator.language == "it-IT"  || navigator.userLanguage == "it-IT"){
        if(window.location.hash) {
          window.location = "/it"+window.location.hash
        } else {
          window.location = "/it"
        }
        
      }else{
        if(window.location.hash) {
          window.location = "/en"+window.location.hash
        } else {
          window.location = "/en"
        }
      }
    }else {
      if(this.$route.params.lang !== 'it' && this.$route.params.lang !== 'en'){
        window.location = '/en'
      }
    }
  }
}
</script>

<style>
  html, body{
    max-width: 100%;
    overflow-x: hidden;
  }
  *{
    font-family: 'Ubuntu', sans-serif;
  }
  .title{
    font-weight: 700;
  }
  .prod-small{
    font-size: small;
  }
  .prod-xsmall{
    font-size: x-small;
  }
  .prod-large{
    font-size: large;
  }
  .prod-xlarge{
    font-size: x-large;
  }
  .underline{
    text-decoration: underline;
  }

</style>