<template>
    <div class="container">
        <div class="row">
            <form class="col s12" id="applyform" name="contact">
                <div class="row">
                    <br>
                    <h5 style="font-weight: bold" class="red-text text-accent-2"><span v-if="$route.params.lang == 'it'">Discutiamo del tuo progetto</span><span v-if="$route.params.lang == 'en'">Let's talk</span></h5>
                    <p class="grey-text"><span v-if="$route.params.lang == 'it'">Inserisci i tuoi recapiti e ti ricontatteremo al più presto. <br> Non condivideremo le informazioni con terze parti.</span><span v-if="$route.params.lang == 'en'">Fill in the details and we'll contact you to discuss your project. <br>All informations will not be shared with third parties.</span></p>
                </div>
                <div class="input-field col s12 l6">
                    <input name="name" class="white-text" type="text">
                    <label v-if="$route.params.lang=='it'" class="white-text">Nome:</label>
                    <label v-if="$route.params.lang=='en'" class="white-text">Name:</label>
                </div>
                <div class="input-field col s12 l6">
                    <input name="email" class="white-text" type="text">
                    <label v-if="$route.params.lang=='it'" class="white-text">Indirizzo Email:</label>
                    <label v-if="$route.params.lang=='en'" class="white-text">Email Address:</label>
                </div>
                <div class="input-field col s12">
                    <textarea class="materialize-textarea white-text" name="message"></textarea>
                    <label v-if="$route.params.lang=='it'" class="white-text">Presentazione del progetto:</label>
                    <label v-if="$route.params.lang=='en'" class="white-text">Introduce your project:</label>
                </div>
            </form>

            <div class="input-field col s12">
                <button @click="submitform()" class="waves-effect waves-light btn red accent-2"><i class="material-icons left">send</i><span v-if="$route.params.lang=='en'">Submit</span><span v-if="$route.params.lang=='it'">Invia</span></button>
            </div>
        </div>
    </div>
</template>

<style>

input[type=text]:focus, textarea.materialize-textarea:focus{
     border-bottom: 1px solid #ff5252!important;
     box-shadow: 0 1px 0 0 #ff5252!important;
}

</style>

<script>
export default {
    data(){
        return{
            meta:{
                title:{
                    it: "H-Music Studio | Contattaci",
                    en: "H-Music Studio | Contact Us"
                },
                description: {
                    it: "H-Music è uno studio di registrazione a Varese vicino Milano. Contattaci o richiedi un preventivo.",
                    en: "H-Music is a recording studio in Varese, close to Milan. Contact us or request a quote."
                }
            }
        }
    },
    /* global $ M */
    mounted(){
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('select');
            M.FormSelect.init(elems);
        });
        $.get('/.netlify/functions/get_services').then(res=>{
            let services = JSON.parse(res)
            services.forEach(element => {
                element.available = false
            });
            this.services = services
        })
    },
    methods:{
        submitform: function(){

            var details = {
                name: document.getElementById('applyform').name.value,
                email: document.getElementById('applyform').email.value,
                message: document.getElementById('applyform').message.value
            };
            
            $.get("../.netlify/functions/send_request", details).then(()=>{
                M.toast({html: 'la tua richiesta è stata inoltrata con successo'})
                window.gtag('event', 'Request',{
                    'event_category': 'Communications',
                    'event_label': 'Quote Request'
                });
                window.fbq('trackCustom', 'Request')
            }).error(err=>{
                console.log(err)
                M.toast({html: 'Si è verificato un errore'})
                window.gtag('event', 'Request');
                window.fbq('trackCustom', 'Request')
            })


        }
    },
    metaInfo() {
        return { 
            htmlAttrs: {
                lang: this.$route.params.lang,
            },
            title: this.meta.title[this.$route.params.lang],
            meta: [
                { name: 'description', content:  this.meta.description[this.$route.params.lang]},
                { property: 'og:title', content: this.meta.title[this.$route.params.lang]},
                { property: 'og:site_name', content: 'H-Music Studio'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
}
</script>