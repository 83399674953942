<template>
    <div class="container">
        <br>
        <div class="row">
            <div class="col s12 l6 white-text">
                <p>
                    <span v-if="$route.params.lang=='it'" class="prod-xlarge title red-text text-accent-2">I nostri servizi<br></span>
                    <span v-if="$route.params.lang=='en'" class="prod-xlarge title red-text text-accent-2">Our Services<br></span>
                    <span v-if="$route.params.lang=='it'" class="grey-text">Esplora in base alle tue esigenze</span>
                    <span v-if="$route.params.lang=='en'" class="grey-text">Explore according to your needs</span>
                
                </p>
            </div>
            <div class="col s12 l6">
                <div class="row">
                    <div class="input-field col s12">
                        <select v-model="request.requester" class="white-text">
                        <option class="white-text" value="" disabled><span v-if="$route.params.lang=='it'">Scegli</span><span v-if="$route.params.lang=='en'">Choose</span></option>
                        <option value="artist"><span v-if="$route.params.lang=='it'">Artista Musicale</span><span v-if="$route.params.lang=='en'">Recording Artist</span></option>
                        <option value="manager">Manager</option>
                        <option value="company"><span v-if="$route.params.lang=='it'">Etichetta Discografica</span><span v-if="$route.params.lang=='en'">Record Label</span></option>
                        </select>
                        <label v-if="$route.params.lang=='it'" class="white-text">Sono un:</label>
                        <label v-if="$route.params.lang=='en'" class="white-text">I am:</label>
                    </div>
                    <div class="input-field col s12">
                        <select v-model="request.services" class="white-text" multiple>
                        <option value="" disabled ><span v-if="$route.params.lang=='it'">Scegli</span><span v-if="$route.params.lang=='en'">Choose</span></option>
                        <option value="rec"><span v-if="$route.params.lang=='it'">Registrare</span><span v-if="$route.params.lang=='en'">To record</span></option>
                        <option value="prod"><span v-if="$route.params.lang=='it'">produttori e songwriters</span><span v-if="$route.params.lang=='en'">Producers &amp; songwriters</span></option>
                        <option value="mixmaster">Mixing &amp; mastering</option>
                        <option value="business"><span v-if="$route.params.lang=='it'">Incrementare la mia fanbase</span><span v-if="$route.params.lang=='en'">To grow my fanbase</span></option>

                        <option value="video"><span v-if="$route.params.lang=='it'">Produrre un video musicale</span><span v-if="$route.params.lang=='en'">Produce a music video</span></option>
                        </select>
                        <label v-if="$route.params.lang=='it'" class="white-text">E ho bisogno di:</label>
                        <label v-if="$route.params.lang=='en'" class="white-text">I need:</label>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="row">

            <div v-for="(service, index) in services" :key="index">
                <div v-if="service.available" class="col s12 m6">
                    <div class="card black">
                        <div class="card-content white-text">
                            <span class="card-title red-text text-accent-2">{{service.title}}</span>
                            <p v-if="$route.params.lang=='it'" style="min-height: 4.5em">{{service.desc.it}}</p>
                            <p v-if="$route.params.lang=='en'" style="min-height: 4.5em">{{service.desc.en}}</p>
                        </div>
                        <div class="card-action">
                            <a v-if="$route.params.lang=='it'" class="red-text text-accent-2" href="./apply">Presentaci il tuo progetto</a>
                            <a v-if="$route.params.lang=='en'" class="red-text text-accent-2" v-bind:href="'./apply?source=services&service='+service.title">Pitch us your project</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.select-wrapper input.select-dropdown {
    border-bottom: 1px solid gray;
    color: rgb(173, 173, 173);
}
.dropdown-content li>span{
    color: gray !important;
    background-color: black!important;
    border: 0.5px solid rgb(46, 46, 46)
}
.select-dropdown li.disabled>span{
    color: gray!important;
}
.select-wrapper input.select-dropdown:focus{
     border-bottom: 1px solid #ff5252;
}
[type="checkbox"]:checked+span:not(.lever):before {
    border-right: 2px solid #ff5252;
    border-bottom: 2px solid #ff5252;
}
</style>

<script>
export default {
    data(){
        return{
            request:{
                requester: "",
                services: []
            },
            services: [],
            meta:{
                title:{
                    it: "H-Music Studio | Servizi",
                    en: "H-Music Studio | Services"
                },
                description: {
                    it: "H-Music è uno studio di registrazione a Varese vicino Milano. Esplora le nostre soluzioni per artisti ed etichette.",
                    en: "H-Music is a recording studio in Varese, close to Milan. Browse our solutions for artists & labels."
                }
            }
        }
    },
    watch: {
        'request': {
            handler(current) {
                console.log(current)
                console.log('lol')
                this.services.forEach((service, index) => {
                    this.services[index].available = false
                    if(service.suitableFor.includes(this.request.requester)){

                        if(service.servicesIncluded.length<=current.services.length){
                            service.servicesIncluded.forEach((serviceIncluded, servicesIndex) => {
                                if(current.services.includes(serviceIncluded)){
                                    this.services[index].available = true
                                }
                                if(servicesIndex == this.services.length-1){
                                    this.services[index].available = false
                                }
                            });
                        }
                    }else{
                        this.services[index].available = false
                    }
                    
                    
                });
            },
            deep: true
        },
    },
    /* global $ M */
    mounted(){
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('select');
            M.FormSelect.init(elems);
        });
        $.get('/.netlify/functions/get_services').then(res=>{
            let services = JSON.parse(res)
            services.forEach(element => {
                element.available = false
            });
            this.services = services
        })
    },
    metaInfo() {
        return { 
            htmlAttrs: {
                lang: this.$route.params.lang,
            },
            title: this.meta.title[this.$route.params.lang],
            meta: [
                { name: 'description', content:  this.meta.description[this.$route.params.lang]},
                { property: 'og:title', content: this.meta.title[this.$route.params.lang]},
                { property: 'og:site_name', content: 'H-Music Studio'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>