import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminLoggedIn: false,
    user:{},
    requests: []
  },
  mutations: {
    /* global $ netlifyIdentity */
    logIn(){
      netlifyIdentity.open('login');
    },
    logout(state){
      netlifyIdentity.logout();
      state.adminLoggedIn = false
    },
    async loggedIn(state){
      state.adminLoggedIn = true
      state.user = netlifyIdentity.currentUser()
      netlifyIdentity.close();
      $.ajax({
        type: "GET",
        url: "/.netlify/functions/get_requests",
        dataType: 'json',
        headers: {
          "Authorization": `Bearer ${netlifyIdentity.currentUser().token.access_token}`
        },
        success: function (res){
          state.requests = res
        }
      });
      
    },
    async flagRequest(state, id){
      fetch("/.netlify/functions/flag_request?id="+id,{
        headers: {
          Authorization: `Bearer ${netlifyIdentity.currentUser().token.access_token}`
        }
      }).then(()=>{
        state.requests.forEach((element,index) => {
          if(element.id == id){
            state.requests[index].converted = true
          }
        });
      })
    }
  },
  actions: {
    flagreq({ commit }, id) {
      commit("flagRequest", id)
    },
  },
  modules: {
  }
})
